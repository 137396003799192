function GameSquare({ info, onClick, isActive }) {
  const prettyDate = new Date(info.gameStartDate).toLocaleString("en-US", {
    day: "numeric",
    year: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const css = `w-52 cursor-pointer rounded ${
    isActive ? "border-4 border-veriBlue1" : "border border-black"
  }`;

  const style = { boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.25)" };
  return (
    <div className={css} onClick={onClick} style={isActive ? style : {}}>
      <div className="w-full h-40 bg-lightBg flex justify-center items-center ">
        <img
          className="h-16 w-16 xrounded-full"
          src={info.homeLogoUrl}
          alt=""
        />
        <span className="w-7 text-center font-bold text-sm">vs.</span>
        <img
          className="h-16 w-16 xrounded-full"
          src={info.awayLogoUrl}
          alt=""
        />
      </div>
      <div className="w-full h-[105px] mt-1.5 px-5">
        <div className="font-bold text-sm">
          <p>{info.homeTeamName} vs</p>
          <p>{info.awayTeamName}</p>
        </div>
        <p className="text-[11px] mt-2.5">{prettyDate}</p>
        <p className="text-[11px] mt-2.5">{info.venueName}</p>
      </div>
    </div>
  );
}

export default GameSquare;
