import { useEffect, useState } from "react";
import Games from "./features/Games/Games";
import GameTime from "./features/GameTime";
import PlayerWidget from "./features/PlayerWidget/PlayerWidget";
import PreviewText from "./features/PreviewText";
import Score from "./features/Score";
import Teams from "./features/Teams/Teams";
import { useGamesRequest, useClipRequest } from "./hooks/api";
import GameInfoRow from "./layout/GameInfoRow";
import Header from "./layout/Header";
import PlayerWidgetRow from "./layout/PlayerWidgetRow";

const DEFAULT_GAME = {
  // id: "default",
  // awayTeamScore: "0",
  // homeTeamScore: "0",
  // durationMinutes: "00",
  // durationSeconds: "00",
};

const initialGame = {
  awayLogoUrl:
    "https://firebasestorage.googleapis.com/v0/b/statsperform-demo.appspot.com/o/logos%2FLAF.svg?alt=media",
  awayTeamName: "Los Angeles",
  awayTeamNameCode: "LAF",
  awayTeamScore: 2,
  durationMinutes: 98,
  durationSeconds: 31,
  gameStartDate: 1658104200000,
  hasOnlyCustomVoice: false,
  homeLogoUrl:
    "https://firebasestorage.googleapis.com/v0/b/statsperform-demo.appspot.com/o/logos%2FNSH.svg?alt=media",
  homeTeamName: "Nashville SC",
  homeTeamNameCode: "NSH",
  homeTeamScore: 1,
  id: "4ML0NX66YL0CKNI649KVD1QMS",
  venueName: "GEODIS Park",
};

const DEFAULT_VOICE = "c";
const DEFAULT_CONTENT_TYPE = "PRE";

function App() {
  const [game, setGame] = useState(initialGame);
  const [voiceId, setVoiceId] = useState(DEFAULT_VOICE);
  const [contentId, setContentId] = useState(DEFAULT_CONTENT_TYPE);

  const gamesReq = useGamesRequest();
  const clipReq = useClipRequest(game.id, voiceId, contentId);

  const textPreview = clipReq.data ? clipReq.data.text : "";
  const isDefaultGame = game.id === DEFAULT_GAME.id;

  let audioUrl = null;
  if (clipReq.data) {
    audioUrl = clipReq.data.generatedAudioUrl || clipReq.data.url;
  }

  let disableVoiceSelect = game.hasOnlyCustomVoice;
  if (game.hasOnlyCustomVoice && voiceId !== "c") {
    //Force the custom voice.
    setVoiceId("c");
  }

  const voiceChangeHandler = (voice) => setVoiceId(voice);
  const contentTypeChangeHandler = (contentType) => setContentId(contentType);
  const gameChangeHandler = (game) => {
    setGame(game);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const PlayerWidgetComp = (
    <PlayerWidget
      isDefault={isDefaultGame}
      isLoading={clipReq.isLoading}
      mediaUrl={audioUrl}
      voiceChangeHandler={voiceChangeHandler}
      voice={voiceId}
      contentTypeChangeHandler={contentTypeChangeHandler}
      defaultContentType={DEFAULT_CONTENT_TYPE}
      disableVoiceSelect={disableVoiceSelect}
    />
  );
  const ScoreComp = (
    <Score
      homeScore={game.homeTeamScore}
      homeCode={game.homeTeamCode}
      awayScore={game.awayTeamScore}
      awayCode={game.awayTeamCode}
    />
  );
  const GameTimeComp = (
    <GameTime
      minutes={game.durationMinutes}
      seconds={game.durationSeconds}
      subText={isDefaultGame ? "" : "Minutes"}
    />
  );

  return (
    <div className="text-white antialiased">
      <Header />
      <main>
        <GameInfoRow showDefault={isDefaultGame}>
          <Teams
            homeTeamName={game.homeTeamName}
            homeLogoUrl={game.homeLogoUrl}
            awayTeamName={game.awayTeamName}
            awayLogoUrl={game.awayLogoUrl}
          />
          <PreviewText text={textPreview} />
        </GameInfoRow>
        <PlayerWidgetRow
          PlayerWidgetComp={PlayerWidgetComp}
          ScoreComp={ScoreComp}
          GameTimeComp={GameTimeComp}
        />
        <Games
          gameChangeHandler={gameChangeHandler}
          gamesList={gamesReq.data ? gamesReq.data : []}
          activeGame={game}
        />
      </main>
    </div>
  );
}

export default App;
