import playIcon from "../../assets/play.svg";
import pauseIcon from "../../assets/pause.svg";
import spinnerIcon from "../../assets/spinner.svg";

function Button({ isLoading, buttonClickHandler, isPlaying, isDefault }) {
  if (isLoading) {
    return (
      <div className="pt-7 lg:pl-10 lg:pt-10">
        <button className="bg-white flex text-sm font-semibold w-24 h-9 justify-center items-center rounded mx-auto lg:mx-0">
          <img className="animate-spin-fast" src={spinnerIcon} alt="Loading" />
        </button>
      </div>
    );
  }

  if (isDefault) {
    return (
      <div className="pt-7 lg:pl-10 lg:pt-10">
        <button
          disabled={true}
          onClick={buttonClickHandler}
          className="bg-gray-300 flex text-sm font-semibold w-24 h-9 justify-center items-center rounded mx-auto lg:mx-0"
        >
          {isPlaying ? (
            <>
              Pause <img className="ml-2" src={pauseIcon} alt="" />
            </>
          ) : (
            <>
              Play <img className="ml-2" src={playIcon} alt="" />
            </>
          )}
        </button>
      </div>
    );
  }

  return (
    <div className="pt-7 lg:pl-10 lg:pt-10">
      <button
        onClick={buttonClickHandler}
        className="bg-btnPrimary flex text-sm font-semibold w-24 h-9 justify-center items-center rounded mx-auto lg:mx-0"
      >
        {isPlaying ? (
          <>
            Pause <img className="ml-2" src={pauseIcon} alt="" />
          </>
        ) : (
          <>
            {isDefault ? "Sample" : "Play"}{" "}
            <img className="ml-2" src={playIcon} alt="" />
          </>
        )}
      </button>
    </div>
  );
}

export default Button;
