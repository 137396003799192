import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function VoiceSelect({ voiceChangeHandler, voice, isDisabled }) {
  const selectChangeHandler = (event) => {
    voiceChangeHandler(event.target.value);
  };

  // Using custom Premade voices for demo instead.
  // const voiceReq = useQuery(["voices"], async () => {
  //   const result = await axios.get(`${process.env.REACT_APP_API_URL}/voices`);
  //   return result.data;
  // });

  // let voiceOptions = [];
  // if (voiceReq.data) {
  //   voiceOptions = voiceReq.data.map((voice) => {
  //     return (
  //       <option key={voice.id} value={voice.id}>
  //         {voice.title}
  //       </option>
  //     );
  //   });
  // }

  let voices = [
    {
      title: "Standard",
      id: "s",
    },
    {
      title: "Enhanced",
      id: "e",
    },
    {
      title: "Enhanced (Spanish)",
      id: "es",
    },
    {
      title: "Custom",
      id: "c",
    },
  ];

  const voiceOptions = voices.map((voice) => {
    return (
      <option key={voice.id} value={voice.id}>
        {voice.title}
      </option>
    );
  });

  return (
    <div
      id="voice-select"
      className="mt-5 lg:mt-3 mb-7 flex flex-col lg:block items-center"
    >
      <label htmlFor="voice-options" className="font-semibold text-sm">
        Select a Voice
      </label>
      <select
        disabled={isDisabled}
        id="voice-options"
        className="block w-48 h-9 rounded border border-lightBg text-secondary pl-3 mt-1"
        onChange={selectChangeHandler}
        value={voice}
      >
        {voiceOptions}
      </select>
    </div>
  );
}

export default VoiceSelect;
