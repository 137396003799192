function PreviewText({ text }) {
  return (
    <div
      id="preview"
      className="text-xs flex flex-col basis-3/5 gap-6 overflow-y-scroll p-4 max-h-32"
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  );
}

export default PreviewText;
