import logo from "../assets/logo.svg";

function Header() {
  return (
    <header className="bg-veriBlue1 flex text-white pt-2.5 pb-4">
      <img alt="Veritone" src={logo} className="ml-5" />
      <div className="ml-3.5">
        <div className="relative">
          <h1 className="font-dosis font-bold text-4xl uppercase leading-8">
            Now Playing
          </h1>
          <div className="right-0 absolute">
            <h2 className="font-bold text-[0.5rem]">by Veritone Voice</h2>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
