function GameInfoRow({ showDefault, children }) {
  if (showDefault) {
    return (
      <div id="game-info" className="p-6 pb-10 md:h-[144px] bg-veriBlue2">
        <h2 className="font-semibold text-[22px] md:w-[630px]">
          Welcome to Now Playing, Your Real Time Sports Connection
        </h2>
        <p className="text-xs font-bold mt-4 md:w-[630px]">
          Select from the Live Games below or come back when your favorite team
          is playing for Pre Game, Play by Play, or Post Game Wrap Up announced
          by any synthetic voice you choose!
        </p>
      </div>
    );
  }

  return (
    <div
      id="game-info"
      className="flex flex-col xl:flex-row bg-veriBlue2 xl:h-32"
    >
      {children}
    </div>
  );
}

export default GameInfoRow;
