function Score({ homeScore, homeTeamCode, awayScore, awayTeamCode }) {
  return (
    <div className="text-xs font-bold">
      <div className="text-center">SCORE</div>
      <div className="flex gap-10">
        <div>
          <div className="text-5xl">{homeScore}</div>
          <div>{homeTeamCode}</div>
        </div>
        <div>
          <div className="text-5xl">{awayScore}</div>
          <div>{awayTeamCode}</div>
        </div>
      </div>
    </div>
  );
}

export default Score;
