import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useClipRequest = (gameId, voiceId, contentId) => {
  return useQuery(["clipAudio", gameId, voiceId, contentId], async () => {
    if (gameId && voiceId && contentId) {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/clipAudio/${gameId}/${voiceId}/${contentId}`
      );
      return result.data;
    }
  });
};

export const useGamesRequest = () => {
  return useQuery(["games"], async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/games`);
    return result.data;
  });
};

export const useDoesFileExist = (url) => {
  return useQuery([url], async () => {
    const result = await axios.head(url);
    return result.data;
  });
};
