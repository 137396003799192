function ContentTypeRadios({ contentTypeChangeHandler, defaultContentType }) {
  const radioChangeHandler = (event) => {
    contentTypeChangeHandler(event.target.value);
  };

  return (
    <div
      id="content-type"
      className="lg:pt-11 lg:pl-11 flex justify-center lg:justify-start gap-5"
      onChange={radioChangeHandler}
    >
      <div className="text-center sm:text-left">
        <input
          type="radio"
          name="contentType"
          id="pre"
          value="PRE"
          className="w-6 h-6 border border-primary align-bottom"
          defaultChecked={defaultContentType === "PRE"}
        />
        <label
          htmlFor="pre"
          className="text-sm ml-2 block sm:inline mt-1 sm:mt-0"
        >
          Pre Game
        </label>
      </div>
      <div className="text-center sm:text-left">
        <input
          type="radio"
          name="contentType"
          id="pbp"
          value="PBP"
          className="w-6 h-6 border border-primary align-bottom"
          defaultChecked={defaultContentType === "PBP"}
        />
        <label
          htmlFor="pbp"
          className="text-sm ml-2 block sm:inline mt-1 sm:mt-0"
        >
          Play-by-Play
        </label>
      </div>
      <div className="text-center sm:text-left">
        <input
          type="radio"
          name="contentType"
          id="post"
          value="POST"
          className="w-6 h-6 border border-primary align-bottom"
          defaultChecked={defaultContentType === "POST"}
        />
        <label
          htmlFor="post"
          className="text-sm ml-2 block sm:inline mt-1 sm:mt-0"
        >
          Post Game
        </label>
      </div>
    </div>
  );
}

export default ContentTypeRadios;
