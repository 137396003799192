function PlayerWidgetRow({ PlayerWidgetComp, ScoreComp, GameTimeComp }) {
  return (
    <div
      id="controls"
      className="flex flex-col xl:flex-row bg-veriBlue1 px-5 pb-5 lg:pb-0"
    >
      <div className="flex flex-col lg:flex-row basis-4/6 justify-center">
        {PlayerWidgetComp}
      </div>
      <div className="basis-2/6 flex pt-5 xl:pt-2 gap-10 justify-center order-first xl:order-none border-b-2 border-white pb-5 xl:pb-0 xl:border-0">
        {ScoreComp}
        {GameTimeComp}
      </div>
    </div>
  );
}

export default PlayerWidgetRow;
