import Team from "./Team";

function Teams({ homeTeamName, homeLogoUrl, awayTeamName, awayLogoUrl }) {
  return (
    <div id="contest" className="flex basis-2/5 justify-center">
      <Team name={homeTeamName} logo={homeLogoUrl} />
      <div className="font-semibold text-3xl mx-4 mt-12">vs</div>
      <Team name={awayTeamName} logo={awayLogoUrl} />
    </div>
  );
}

export default Teams;
