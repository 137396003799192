function GameTime({ minutes, seconds, subText }) {
  const format = {
    minimumIntegerDigits: 2,
    useGrouping: false,
  };
  const prettyMinutes = minutes.toLocaleString("en-US", format);
  const prettySeconds = seconds.toLocaleString("en-US", format);

  return (
    <div className="text-xs font-bold">
      <div>GAME TIME</div>
      <div className="flex gap-14">
        <div>
          <div className="text-5xl">
            {prettyMinutes}:{prettySeconds}
          </div>
          <div>{subText}</div>
        </div>
      </div>
    </div>
  );
}

export default GameTime;
