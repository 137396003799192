import { useEffect, useState } from "react";
import Button from "./Button";
import ContentTypeRadios from "./ContentTypeRadios";
import VoiceSelect from "./VoiceSelect";
import { axios } from "axios";
import { useDoesFileExist } from "../../hooks/api";

function PlayerWidget({
  voice,
  voiceChangeHandler,
  contentTypeChangeHandler,
  defaultContentType,
  isLoading,
  isDefault,
  mediaUrl,
  disableVoiceSelect,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayer = document.getElementById("audioPlayer");

  useEffect(() => {
    audioPlayer.onended = (event) => {
      setIsPlaying(false);
    };
    audioPlayer.onplay = (event) => {
      setIsPlaying(true);
    };
  }, [audioPlayer]);

  useEffect(() => {
    if (mediaUrl) {
      audioPlayer.src = mediaUrl;
    }
    setIsPlaying(false);
  }, [mediaUrl, audioPlayer]);

  useEffect(() => {
    if (isPlaying) {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
  }, [isPlaying, audioPlayer]);

  const togglePlayState = () => {
    setIsPlaying((previousState) => {
      return !previousState;
    });
  };

  if (!mediaUrl) {
    isDefault = true; //Disable the play button if no mediaUrl
  }

  // const fileCheckReq = useDoesFileExist(mediaUrl);

  // if (mediaUrl) {
  //   //Test media url

  //   if (fileCheckReq.error) {
  //     console.log(mediaUrl);
  //     console.log(fileCheckReq);
  //     isDefault = true; //Disable the play button if no mediaUrl
  //   }
  // }

  return (
    <>
      <VoiceSelect
        voiceChangeHandler={voiceChangeHandler}
        voice={voice}
        isDisabled={disableVoiceSelect}
      />
      <ContentTypeRadios
        contentTypeChangeHandler={contentTypeChangeHandler}
        defaultContentType={defaultContentType}
      />
      <Button
        isLoading={isLoading}
        isPlaying={isPlaying}
        isDefault={isDefault}
        buttonClickHandler={togglePlayState}
      />
      <div
        id="divider"
        className="border-r-2 border-white my-3 grow hidden xl:block"
      ></div>
    </>
  );
}

export default PlayerWidget;
