import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import GameSquare from "./GameSquare";

function Games({ gameChangeHandler, gamesList, activeGame }) {
  if (gamesList) {
    const squares = gamesList.map((game) => {
      return (
        <GameSquare
          key={game.id}
          info={game}
          isActive={activeGame.id === game.id}
          onClick={(event) => {
            gameChangeHandler(game);
          }}
        />
      );
    });

    return (
      <div className="text-black flex gap-8 p-8 flex-wrap justify-center">
        {squares}
      </div>
    );
  }
}

export default Games;
