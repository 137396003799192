function Team({ name, logo }) {
  return (
    <div className="w-32 text-center my-4">
      <img src={logo} alt="Team Logo" className="mx-auto h-16 w-16" />
      <p className="font-semibold text-sm mt-1.5 px-4">{name}</p>
    </div>
  );
}

export default Team;
